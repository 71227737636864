import Form, { formCreate } from '../../../../../../components/form';
import request from '../../../../../../utils/request';
import WriteOff from '../components/edit_write_off.vue';
import Invoice from '../components/invoice_manage.vue';

formCreate.component('accountDetailVos', WriteOff);
formCreate.component('accountInvoiceVos', Invoice);

export default {
  extends: Form,
  components: {
    formCreate: formCreate.$form(),
  },
  data() {
    return {
      formFunctionCode: 'TpmAccountDetailRespVo-form',
      buttonText: {
        submit: '暂存',
        submitAudit: '提交',
      },
      buttons: {
        submitAudit: this.formConfig.code !== 'view',
      },
    };
  },
  methods: {
    // 设置rule
    setRule(item) {
      const v = item;
      if (v.field === 'accountInvoiceVos') {
        v.props = {
          ...v.props,
          getList: (field, method) => this.fApi.method(field, method),
        };
      }
      return v;
    },
    // 表单渲染完成后回调
    formComplete() {
      this.hiddenFields(true, 'accountInvoiceVos');

      const detailVos = this.getRule('accountDetailVos');
      const invoiceVos = this.getRule('accountInvoiceVos');

      detailVos.on.change = (e, row) => {
        const payTypeType = e.map((i) => i.payTypeType);
        this.hiddenFields(payTypeType[0] !== 'need_invoice', 'accountInvoiceVos');

        const params = {
          accountDetailVos: e,
        };

        if (e.length === 0) {
          params.accountInvoiceVos = [];
        } else if (row && invoiceVos.value && invoiceVos.value.length > 0) {
          const _list = invoiceVos.value.filter((v) => v.accountCodes !== row.accountCode);

          params.accountInvoiceVos = _list.map((item) => {
            const v = item;
            if (v.accountCodeList.includes(row.accountCode)) {
              v.accountCodes = v.accountCodeList.filter((code) => code !== row.accountCode).join(',');
              v.accountCodeList = v.accountCodeList.filter((code) => code !== row.accountCode);
            }

            return v;
          });
        }

        this.setValue(params);
      };

      if (this.formConfig.code === 'edit' || this.formConfig.code === 'view') {
        request
          .get('/tpm/tpmAccountDetailController/queryView', {
            id: this.formConfig.row.id,
          })
          .then((res) => {
            if (res.success) {
              const data = res.result;

              data.accountDetailVos = data.accountDetailVos.map((item) => {
                const v = item;
                v.accountProductRespVos = v.tpmProductInfoReqVos ? v.tpmProductInfoReqVos.map((sub) => sub.productCode) : null;

                if (v.productLevelCode) {
                  v.levelOptions = [{
                    productLevelCode: v.productLevelCode,
                    productLevelName: v.productLevelName,
                  }];
                }

                v.productOptions = v.tpmProductInfoReqVos || [];

                return v;
              });

              this.hiddenFields(data.accountDetailVos[0].payTypeType !== 'need_invoice', 'accountInvoiceVos');

              this.setValue(data);
            }
          });
      }
    },
    // 提交
    submit(e) {
      const formData = this.getFormData(); // 获取表单的值
      if (formData) {
        let url = '/tpm/tpmAccountDetailController/save';
        const params = { ...formData };

        const { accountDetailVos: detail, accountInvoiceVos: invoice } = params;

        params.saveType = e === 1 ? 'temp' : 'submit';
        params.tpmProjectName = 'tpm';

        if (this.formConfig.code === 'edit') {
          url = '/tpm/tpmAccountDetailController/update';
          params.id = this.formConfig.row.id;
        }

        if (detail && detail.length > 0) {
          params.accountDetailVos = detail.map((item) => {
            const v = item;

            const product = v.productOptions ? v.productOptions.filter((sub) => v.accountProductRespVos.includes(sub.productCode)) : null;
            v.tpmProductInfoReqVos = product ? product.map((p) => ({
              productCode: p.productCode,
              productName: p.productName,
              productLevelCode: v.productLevelCode,
              productLevelName: v.productLevelName,
            })) : [];
            return v;
          });
        }

        request.post(url, params).then((res) => {
          if (res.success) {
            this.$message({
              type: 'success',
              message: '操作成功',
            });
            this.$emit('onClose');
            this.$emit('onGetList');
          }
        });
      }
    },
  },
};
