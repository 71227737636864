<template>
  <div class="write-off-table">
    <vxe-toolbar v-if="!disabled">
      <template v-slot:buttons>
        <el-button type="text" icon="el-icon-plus" @click="selectModal">选择</el-button>
        <el-button type="text" icon="el-icon-upload2">导入</el-button>
        <el-button type="text" icon="el-icon-edit" @click="batchEditFn">批量修改支付类型</el-button>
        <el-button class="text-red" type="text" icon="el-icon-delete" @click="deleteTable"
          >清空</el-button
        >
      </template>
    </vxe-toolbar>
    <div class="list">
      <vxe-table
        border
        show-overflow
        ref="xTable"
        :data="value"
        @checkbox-change="checkboxChange"
        @checkbox-all="checkboxAll"
        :checkbox-config="{
          checkRowKeys: selection
        }"
        rowId="auditDetailCode"
      >
        <vxe-table-column
          type="checkbox"
          fixed="left"
          min-width="36"
          v-if="!disabled"
        ></vxe-table-column>
        <vxe-table-column title="上账编码" field="accountCode" min-width="100"></vxe-table-column>
        <vxe-table-column title="活动编码" field="actCode" min-width="100"></vxe-table-column>
        <vxe-table-column title="活动名称" field="actName" min-width="100"></vxe-table-column>
        <vxe-table-column
          title="活动明细编码"
          field="actDetailCode"
          min-width="100"
        ></vxe-table-column>
        <vxe-table-column title="核销编码" field="auditCode" min-width="110"></vxe-table-column>
        <vxe-table-column
          title="核销明细编码"
          field="auditDetailCode"
          min-width="100"
        ></vxe-table-column>
        <vxe-table-column title="活动细类名称" field="fineName" min-width="100"></vxe-table-column>
        <vxe-table-column
          title="活动大类名称"
          field="categoriesName"
          min-width="100"
        ></vxe-table-column>
        <vxe-table-column title="客户编码" field="customerCode" min-width="100"></vxe-table-column>
        <vxe-table-column title="客户名称" field="customerName" min-width="100"></vxe-table-column>
        <vxe-table-column title="门店编码" field="terminalCode" min-width="100"></vxe-table-column>
        <vxe-table-column title="门店名称" field="terminalName" min-width="100"></vxe-table-column>
        <vxe-table-column title="企业组织编码" field="orgCode" min-width="100"></vxe-table-column>
        <vxe-table-column title="企业组织名称" field="orgName" min-width="100"></vxe-table-column>
        <vxe-table-column
          title="本次可上账金额"
          field="currentAllowAmount"
          min-width="130"
        ></vxe-table-column>
        <vxe-table-column title="上账金额" field="accountAmount" min-width="150">
          <template #default="{ row }">
            <el-input-number
              v-model="row.accountAmount"
              :controls="false"
              :min="0"
              :max="row.currentAllowAmount"
              :precision="2"
              v-if="!disabled"
            ></el-input-number>
            <span v-else>{{ row.accountAmount }}</span>
          </template>
        </vxe-table-column>
        <vxe-table-column
          title="支付方式名称"
          field="payTypeName"
          min-width="100"
        ></vxe-table-column>
        <vxe-table-column title="产品层级" field="productLevelCode" min-width="150">
          <template #default="{ row, rowIndex }">
            <el-select
              v-model="row.productLevelCode"
              filterable
              remote
              placeholder="产品层级"
              @focus="() => remoteMethod('lev', rowIndex)"
              :remote-method="v => remoteMethod('lev', rowIndex, v)"
              @change="v => levelChange(v, rowIndex)"
              :loading="loading"
              clearable
              :disabled="row.payType !== '4'"
              v-if="!disabled"
            >
              <el-option
                v-for="item in row.levelOptions"
                :key="item.value"
                :label="item.productLevelName"
                :value="item.productLevelCode"
              ></el-option>
            </el-select>
            <span v-else>{{ row.productLevelName }}</span>
          </template>
        </vxe-table-column>
        <!-- <vxe-table-column title="产品层级名称" field="productLevelName" min-width="100"></vxe-table-column> -->
        <!-- <vxe-table-column title="产品数据" field="accountProductRespVos" min-width="100"></vxe-table-column> -->
        <vxe-table-column title="产品数据" field="accountProductRespVos" min-width="200">
          <template #default="{ row, rowIndex }">
            <el-select
              v-model="row.accountProductRespVos"
              filterable
              remote
              reserve-keyword
              placeholder="产品数据"
              @focus="() => remoteMethod('pro', rowIndex)"
              :remote-method="v => remoteMethod('pro', rowIndex, v)"
              :loading="loading"
              clearable
              :disabled="row.payType !== '4'"
              multiple
              collapse-tags
              v-if="!disabled"
            >
              <el-option
                v-for="item in row.productOptions"
                :key="item.value"
                :label="item.productName"
                :value="item.productCode"
              ></el-option>
            </el-select>
            <span v-else>{{ row.productName }}</span>
          </template>
        </vxe-table-column>
        <vxe-table-column
          title="财务凭证编码"
          field="credentialsCode"
          min-width="100"
        ></vxe-table-column>
        <!-- <vxe-table-column title="核销科目编码" field="budgetSubjectsCode" min-width="100"></vxe-table-column>
        <vxe-table-column title="核销科目名称" field="budgetSubjectsName" min-width="100"></vxe-table-column> -->
        <vxe-table-column
          title="ERP会计科目编码"
          field="financeSubjectsErpCode"
          min-width="120"
        ></vxe-table-column>
        <vxe-table-column
          title="ERP会计科目名称"
          field="financeSubjectsErpName"
          min-width="120"
        ></vxe-table-column>
        <vxe-table-column title="结束日期" field="endDate" min-width="100"></vxe-table-column>
        <vxe-table-column title="结束时间" field="endDateSecond" min-width="100"></vxe-table-column>
        <vxe-table-column title="备注" field="remarks" min-width="150">
          <template #default="{ row }">
            <el-input placeholder="请输入内容" v-model="row.remarks"></el-input>
          </template>
        </vxe-table-column>
        <vxe-table-column v-if="!disabled" title="操作" width="60" fixed="right" align="center">
          <template v-slot="{ row }">
            <div class="setting-btn">
              <el-popconfirm
                title="此操作将永久删除该数据？"
                confirm-button-type="text"
                @confirm="removeRow(row)"
              >
                <el-button slot="reference" type="text">
                  <i class="el-icon-delete" style="color: #f5222d"></i>
                </el-button>
              </el-popconfirm>
            </div>
          </template>
        </vxe-table-column>
      </vxe-table>
    </div>
    <SelectConfig ref="selectConfig" @onGetSelect="onGetSelect" :beforeClose="beforeClose" />
    <SubModal ref="subModal" @resetList="resetList" />
  </div>
</template>

<script>
import SelectConfig from '../../../../../../components/select_org_cus_ter/select_config.vue';
import SubModal from './modal.vue';
import request from '../../../../../../utils/request';

export default {
  name: 'write_off',
  props: {
    value: Array,
    disabled: Boolean,
  },
  data() {
    return {
      selectRow: [],
      selection: [],
      loading: true,
    };
  },
  components: {
    SelectConfig,
    SubModal,
  },
  methods: {
    // 核销选择列表
    selectModal() {
      const codes = this.value ? this.value.map((v) => v.auditDetailAttachCode) : [];
      const params = {
        functionCode: 'TpmAuditDetailRespVo-list',
        data: [],
        selectionList: [],
        idKey: 'auditDetailAttachCode',
        paramData: {
          notInCodeList: codes,
        },
      };
      this.$refs.selectConfig.openSelectModal(params);
    },
    // 确定选择关闭弹框前置函数
    beforeClose(val) {
      const payTypeType = val.map((v) => v.payTypeType);

      const state = payTypeType.every((v) => v === payTypeType[0]);
      if (!state) {
        this.$message.error('请选择相同支付方式类型');
      }

      return state;
    },
    // 选择数据回调
    async onGetSelect(val) {
      let num = val.length;

      const url = '/tpm/tpmAuditDetailController/generateAccountCode';
      const res = await request.get(url, { num });

      let list = JSON.parse(JSON.stringify(val));

      list = list.map((item) => {
        const v = item;
        if (!v.accountCode) {
          v.accountCode = res.result[num - 1];
          num -= 1;
        }

        v.productLevelCode = '';
        v.productCode = '';

        delete v.id;
        return v;
      });

      const newList = (this.value || []).concat(list);

      this.$emit('change', newList);
    },
    // 删除行数据
    removeRow(row) {
      const index = this.$refs.xTable.getRowIndex(row);
      const list = this.value.filter((v, k) => k !== index);

      this.selectRow = this.selectRow.filter(
        (v) => v.auditDetailAttachCode !== row.auditDetailAttachCode,
      );

      this.$emit('change', list, row);
    },
    // 清除
    deleteTable() {
      this.$confirm('请确认是否要清空当前数据？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          this.$emit('change', []);
          this.selectRow = [];
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消',
          });
        });
    },
    // 批量修改支付方式
    batchEditFn() {
      if (this.selectRow.length === 0) {
        this.$message.warning('请选择明细再进行操作！');
      } else {
        const params = {
          title: '批量修改支付方式',
          code: 'payment',
          url: '/tpm/tpmAccountDetailController/batchUpdatePayType',
          row: {
            ids: this.selectRow.map((i) => i.id),
            fineCodes: this.selectRow.map((i) => i.fineCode),
            payTypeType: this.selectRow[0] && this.selectRow[0].payTypeType,
          },
        };
        this.$refs.subModal.openModal(params);
      }
    },
    // 全选
    checkboxAll({ records }) {
      this.selectRow = records;
      this.selection = records.map((v) => v.auditDetailCode);
    },
    // 多选
    checkboxChange({ records }) {
      this.selectRow = records;
      this.selection = records.map((v) => v.auditDetailCode);
    },
    // 更新支付类型修改
    resetList(payType) {
      this.selectRow = this.selectRow.map((item) => {
        const v = item;
        return {
          ...v,
          ...payType,
        };
      });

      let list = JSON.parse(JSON.stringify(this.value));

      list = list.map((item) => {
        let v = item;
        if (this.selection.includes(v.auditDetailCode)) {
          v = {
            ...v,
            ...payType,
          };
        }

        return v;
      });

      this.$emit('change', list);
    },
    // 获取当前选择数据
    getCheckList() {
      return this.selectRow;
    },
    // 下拉框获取数据
    async remoteMethod(type, index, query) {
      this.loading = true;
      let url = '/mdm/mdmProductLevelController/productLevelSelectList';
      let params = {
        enableStatus: '009',
        productLevelName: query || '',
      };

      if (type === 'pro') {
        const code = this.value[index].productLevelCode;
        url = '/mdm/mdmProductController/productSelectList';
        params = {
          enableStatus: '009',
          productName: query || '',
          productLevelCode: code || '',
        };
      }
      const res = await request.post(url, params);
      this.loading = false;

      if (res.success) {
        if (type === 'pro') {
          this.value[index].productOptions = res.result;
        } else {
          this.value[index].levelOptions = res.result;
        }
      }
    },
    // 层级修改
    levelChange(val, index) {
      const options = this.value[index].levelOptions.find((v) => v.productLevelCode === val);
      this.value[index].accountProductRespVos = [];
      this.value[index].productLevelName = options ? options.productLevelName : '';
    },
  },
};
</script>

<style lang="less" scoped>
.write-off-table {
  .text-red {
    color: #f56c6c !important;
  }
}
</style>
