<template>
  <div class="write-off-table">
    <vxe-toolbar v-if="!disabled">
      <template v-slot:buttons>
        <el-button type="text" icon="el-icon-plus" @click="openModal">新增</el-button>
        <!-- <el-button type="text">导入发票</el-button>
        <el-button type="text">上传</el-button>-->
        <el-button class="text-red" type="text" icon="el-icon-delete" @click="deleteTable">清空</el-button>
      </template>
    </vxe-toolbar>
    <div class="list">
      <vxe-table border show-overflow ref="xTable" :data="value">
        <vxe-table-column title="上账总单号" field="accountCodes" min-width="100"></vxe-table-column>
        <vxe-table-column title="发票编号" field="invoiceCode" min-width="100"></vxe-table-column>
        <!-- <vxe-table-column
          title="申请科目编号"
          field="applyBudgetSubjectsCode"
          min-width="100"
        ></vxe-table-column>
        <vxe-table-column
          title="申请科目"
          field="applyBudgetSubjectsName"
          min-width="100"
        ></vxe-table-column>-->
        <vxe-table-column title="上账科目编号" field="accountBudgetSubjectsCode" min-width="100"></vxe-table-column>
        <vxe-table-column title="上账科目" field="accountBudgetSubjectsName" min-width="100"></vxe-table-column>
        <vxe-table-column title="税金" field="taxAmount" min-width="100"></vxe-table-column>
        <vxe-table-column title="发票金额（不含税）" field="invoiceAmount" min-width="130"></vxe-table-column>
        <vxe-table-column title="发票总金额（含税）" field="countAmount" min-width="130"></vxe-table-column>
        <!-- <vxe-table-column title="进项税转出金额" field="" min-width="110"></vxe-table-column> -->
        <vxe-table-column title="ERP成本中心" field="costCenter" min-width="100"></vxe-table-column>
        <vxe-table-column title="摘要" field="remarks" min-width="100"></vxe-table-column>
        <vxe-table-column v-if="!disabled" title="操作" width="100" fixed="right" align="center">
          <template v-slot="{ row }">
            <div class="setting-btn">
              <el-button
                slot="reference"
                type="text"
                @click="editFn(row)"
                style="margin-right: 10px"
              >编辑</el-button>
              <el-popconfirm
                title="此操作将永久删除该数据？"
                confirm-button-type="text"
                @confirm="removeRow(row)"
              >
                <el-button class="text-red" slot="reference" type="text">删除</el-button>
              </el-popconfirm>
            </div>
          </template>
        </vxe-table-column>
      </vxe-table>
    </div>
    <SubModal ref="subModal" @resetList="resetList" />
  </div>
</template>

<script>
import SubModal from './modal.vue';

export default {
  name: 'invoice',
  props: {
    value: Array,
    disabled: Boolean,
    getList: Function,
  },
  data() {
    return {};
  },
  components: {
    SubModal,
  },
  methods: {
    verifyData() {
      const list = this.getList('accountDetailVos', 'getCheckList')();

      let state = true;
      let msg = '';

      if (list.length === 0) {
        state = false;
        msg = '请选择明细再进行新增发票操作！';
      } else {
        const customerCodes = list.map((v) => v.customerCode);
        state = customerCodes.every((v) => v === customerCodes[0]);
        msg = !state ? '只能选择同一客户的明细进行操作！' : '';
      }
      console.log(list);

      return {
        state,
        msg,
        list,
      };
    },
    // 弹框新增
    openModal() {
      const { state, msg, list } = this.verifyData();

      if (!state) {
        this.$message.warning(msg);
      } else {
        const params = {
          title: '新增',
          code: 'add',
          formName: 'invoiceForm',
          row: {
            customerName: list[0].customerName,
            fineCodeList: list.map((v) => v.fineCode),
            accountCodes: list.map((v) => v.accountCode).join(','),
            accountCodeList: list.map((v) => v.accountCode),
          },
        };
        this.$refs.subModal.openModal(params);
      }
    },
    // 编辑发票信息
    editFn(row) {
      const params = {
        title: '编辑',
        code: 'edit',
        formName: 'invoiceForm',
        row: {
          // customerName: list[0].customerName,
          // fineCodeList: list.map((v) => v.fineCode),
          ...row,
        },
      };
      this.$refs.subModal.openModal(params);
    },
    // 选择数据回调
    resetList({ code, formData }) {
      const list = JSON.parse(JSON.stringify(this.value || []));

      if (code === 'add') {
        list.push(formData);
      }

      this.$emit('input', list);
    },
    // 删除行数据
    removeRow(row) {
      const index = this.$refs.xTable.getRowIndex(row);
      const list = this.value.filter((v, k) => k !== index);
      this.$emit('input', list);
    },
    // 清除
    deleteTable() {
      this.$confirm('请确认是否要清空当前数据？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          this.$emit('change', []);
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: `已取消${data.msg}`,
          });
        });
    },
  },
};
</script>

<style lang="less" scoped>
.write-off-table {
  .text-red {
    color: #f56c6c!important;
  }
}
</style>
