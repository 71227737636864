var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "write-off-table" },
    [
      !_vm.disabled
        ? _c("vxe-toolbar", {
            scopedSlots: _vm._u(
              [
                {
                  key: "buttons",
                  fn: function () {
                    return [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "text", icon: "el-icon-plus" },
                          on: { click: _vm.selectModal },
                        },
                        [_vm._v("选择")]
                      ),
                      _c(
                        "el-button",
                        { attrs: { type: "text", icon: "el-icon-upload2" } },
                        [_vm._v("导入")]
                      ),
                      _c(
                        "el-button",
                        {
                          attrs: { type: "text", icon: "el-icon-edit" },
                          on: { click: _vm.batchEditFn },
                        },
                        [_vm._v("批量修改支付类型")]
                      ),
                      _c(
                        "el-button",
                        {
                          staticClass: "text-red",
                          attrs: { type: "text", icon: "el-icon-delete" },
                          on: { click: _vm.deleteTable },
                        },
                        [_vm._v("清空")]
                      ),
                    ]
                  },
                  proxy: true,
                },
              ],
              null,
              false,
              4112823928
            ),
          })
        : _vm._e(),
      _c(
        "div",
        { staticClass: "list" },
        [
          _c(
            "vxe-table",
            {
              ref: "xTable",
              attrs: {
                border: "",
                "show-overflow": "",
                data: _vm.value,
                "checkbox-config": {
                  checkRowKeys: _vm.selection,
                },
                rowId: "auditDetailCode",
              },
              on: {
                "checkbox-change": _vm.checkboxChange,
                "checkbox-all": _vm.checkboxAll,
              },
            },
            [
              !_vm.disabled
                ? _c("vxe-table-column", {
                    attrs: {
                      type: "checkbox",
                      fixed: "left",
                      "min-width": "36",
                    },
                  })
                : _vm._e(),
              _c("vxe-table-column", {
                attrs: {
                  title: "上账编码",
                  field: "accountCode",
                  "min-width": "100",
                },
              }),
              _c("vxe-table-column", {
                attrs: {
                  title: "活动编码",
                  field: "actCode",
                  "min-width": "100",
                },
              }),
              _c("vxe-table-column", {
                attrs: {
                  title: "活动名称",
                  field: "actName",
                  "min-width": "100",
                },
              }),
              _c("vxe-table-column", {
                attrs: {
                  title: "活动明细编码",
                  field: "actDetailCode",
                  "min-width": "100",
                },
              }),
              _c("vxe-table-column", {
                attrs: {
                  title: "核销编码",
                  field: "auditCode",
                  "min-width": "110",
                },
              }),
              _c("vxe-table-column", {
                attrs: {
                  title: "核销明细编码",
                  field: "auditDetailCode",
                  "min-width": "100",
                },
              }),
              _c("vxe-table-column", {
                attrs: {
                  title: "活动细类名称",
                  field: "fineName",
                  "min-width": "100",
                },
              }),
              _c("vxe-table-column", {
                attrs: {
                  title: "活动大类名称",
                  field: "categoriesName",
                  "min-width": "100",
                },
              }),
              _c("vxe-table-column", {
                attrs: {
                  title: "客户编码",
                  field: "customerCode",
                  "min-width": "100",
                },
              }),
              _c("vxe-table-column", {
                attrs: {
                  title: "客户名称",
                  field: "customerName",
                  "min-width": "100",
                },
              }),
              _c("vxe-table-column", {
                attrs: {
                  title: "门店编码",
                  field: "terminalCode",
                  "min-width": "100",
                },
              }),
              _c("vxe-table-column", {
                attrs: {
                  title: "门店名称",
                  field: "terminalName",
                  "min-width": "100",
                },
              }),
              _c("vxe-table-column", {
                attrs: {
                  title: "企业组织编码",
                  field: "orgCode",
                  "min-width": "100",
                },
              }),
              _c("vxe-table-column", {
                attrs: {
                  title: "企业组织名称",
                  field: "orgName",
                  "min-width": "100",
                },
              }),
              _c("vxe-table-column", {
                attrs: {
                  title: "本次可上账金额",
                  field: "currentAllowAmount",
                  "min-width": "130",
                },
              }),
              _c("vxe-table-column", {
                attrs: {
                  title: "上账金额",
                  field: "accountAmount",
                  "min-width": "150",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        !_vm.disabled
                          ? _c("el-input-number", {
                              attrs: {
                                controls: false,
                                min: 0,
                                max: row.currentAllowAmount,
                                precision: 2,
                              },
                              model: {
                                value: row.accountAmount,
                                callback: function ($$v) {
                                  _vm.$set(row, "accountAmount", $$v)
                                },
                                expression: "row.accountAmount",
                              },
                            })
                          : _c("span", [_vm._v(_vm._s(row.accountAmount))]),
                      ]
                    },
                  },
                ]),
              }),
              _c("vxe-table-column", {
                attrs: {
                  title: "支付方式名称",
                  field: "payTypeName",
                  "min-width": "100",
                },
              }),
              _c("vxe-table-column", {
                attrs: {
                  title: "产品层级",
                  field: "productLevelCode",
                  "min-width": "150",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      var rowIndex = ref.rowIndex
                      return [
                        !_vm.disabled
                          ? _c(
                              "el-select",
                              {
                                attrs: {
                                  filterable: "",
                                  remote: "",
                                  placeholder: "产品层级",
                                  "remote-method": function (v) {
                                    return _vm.remoteMethod("lev", rowIndex, v)
                                  },
                                  loading: _vm.loading,
                                  clearable: "",
                                  disabled: row.payType !== "4",
                                },
                                on: {
                                  focus: function () {
                                    return _vm.remoteMethod("lev", rowIndex)
                                  },
                                  change: function (v) {
                                    return _vm.levelChange(v, rowIndex)
                                  },
                                },
                                model: {
                                  value: row.productLevelCode,
                                  callback: function ($$v) {
                                    _vm.$set(row, "productLevelCode", $$v)
                                  },
                                  expression: "row.productLevelCode",
                                },
                              },
                              _vm._l(row.levelOptions, function (item) {
                                return _c("el-option", {
                                  key: item.value,
                                  attrs: {
                                    label: item.productLevelName,
                                    value: item.productLevelCode,
                                  },
                                })
                              }),
                              1
                            )
                          : _c("span", [_vm._v(_vm._s(row.productLevelName))]),
                      ]
                    },
                  },
                ]),
              }),
              _c("vxe-table-column", {
                attrs: {
                  title: "产品数据",
                  field: "accountProductRespVos",
                  "min-width": "200",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      var rowIndex = ref.rowIndex
                      return [
                        !_vm.disabled
                          ? _c(
                              "el-select",
                              {
                                attrs: {
                                  filterable: "",
                                  remote: "",
                                  "reserve-keyword": "",
                                  placeholder: "产品数据",
                                  "remote-method": function (v) {
                                    return _vm.remoteMethod("pro", rowIndex, v)
                                  },
                                  loading: _vm.loading,
                                  clearable: "",
                                  disabled: row.payType !== "4",
                                  multiple: "",
                                  "collapse-tags": "",
                                },
                                on: {
                                  focus: function () {
                                    return _vm.remoteMethod("pro", rowIndex)
                                  },
                                },
                                model: {
                                  value: row.accountProductRespVos,
                                  callback: function ($$v) {
                                    _vm.$set(row, "accountProductRespVos", $$v)
                                  },
                                  expression: "row.accountProductRespVos",
                                },
                              },
                              _vm._l(row.productOptions, function (item) {
                                return _c("el-option", {
                                  key: item.value,
                                  attrs: {
                                    label: item.productName,
                                    value: item.productCode,
                                  },
                                })
                              }),
                              1
                            )
                          : _c("span", [_vm._v(_vm._s(row.productName))]),
                      ]
                    },
                  },
                ]),
              }),
              _c("vxe-table-column", {
                attrs: {
                  title: "财务凭证编码",
                  field: "credentialsCode",
                  "min-width": "100",
                },
              }),
              _c("vxe-table-column", {
                attrs: {
                  title: "ERP会计科目编码",
                  field: "financeSubjectsErpCode",
                  "min-width": "120",
                },
              }),
              _c("vxe-table-column", {
                attrs: {
                  title: "ERP会计科目名称",
                  field: "financeSubjectsErpName",
                  "min-width": "120",
                },
              }),
              _c("vxe-table-column", {
                attrs: {
                  title: "结束日期",
                  field: "endDate",
                  "min-width": "100",
                },
              }),
              _c("vxe-table-column", {
                attrs: {
                  title: "结束时间",
                  field: "endDateSecond",
                  "min-width": "100",
                },
              }),
              _c("vxe-table-column", {
                attrs: { title: "备注", field: "remarks", "min-width": "150" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        _c("el-input", {
                          attrs: { placeholder: "请输入内容" },
                          model: {
                            value: row.remarks,
                            callback: function ($$v) {
                              _vm.$set(row, "remarks", $$v)
                            },
                            expression: "row.remarks",
                          },
                        }),
                      ]
                    },
                  },
                ]),
              }),
              !_vm.disabled
                ? _c("vxe-table-column", {
                    attrs: {
                      title: "操作",
                      width: "60",
                      fixed: "right",
                      align: "center",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (ref) {
                            var row = ref.row
                            return [
                              _c(
                                "div",
                                { staticClass: "setting-btn" },
                                [
                                  _c(
                                    "el-popconfirm",
                                    {
                                      attrs: {
                                        title: "此操作将永久删除该数据？",
                                        "confirm-button-type": "text",
                                      },
                                      on: {
                                        confirm: function ($event) {
                                          return _vm.removeRow(row)
                                        },
                                      },
                                    },
                                    [
                                      _c(
                                        "el-button",
                                        {
                                          attrs: {
                                            slot: "reference",
                                            type: "text",
                                          },
                                          slot: "reference",
                                        },
                                        [
                                          _c("i", {
                                            staticClass: "el-icon-delete",
                                            staticStyle: { color: "#f5222d" },
                                          }),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      247235278
                    ),
                  })
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _c("SelectConfig", {
        ref: "selectConfig",
        attrs: { beforeClose: _vm.beforeClose },
        on: { onGetSelect: _vm.onGetSelect },
      }),
      _c("SubModal", { ref: "subModal", on: { resetList: _vm.resetList } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }