var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "write-off-table" },
    [
      !_vm.disabled
        ? _c("vxe-toolbar", {
            scopedSlots: _vm._u(
              [
                {
                  key: "buttons",
                  fn: function () {
                    return [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "text", icon: "el-icon-plus" },
                          on: { click: _vm.openModal },
                        },
                        [_vm._v("新增")]
                      ),
                      _c(
                        "el-button",
                        {
                          staticClass: "text-red",
                          attrs: { type: "text", icon: "el-icon-delete" },
                          on: { click: _vm.deleteTable },
                        },
                        [_vm._v("清空")]
                      ),
                    ]
                  },
                  proxy: true,
                },
              ],
              null,
              false,
              457912373
            ),
          })
        : _vm._e(),
      _c(
        "div",
        { staticClass: "list" },
        [
          _c(
            "vxe-table",
            {
              ref: "xTable",
              attrs: { border: "", "show-overflow": "", data: _vm.value },
            },
            [
              _c("vxe-table-column", {
                attrs: {
                  title: "上账总单号",
                  field: "accountCodes",
                  "min-width": "100",
                },
              }),
              _c("vxe-table-column", {
                attrs: {
                  title: "发票编号",
                  field: "invoiceCode",
                  "min-width": "100",
                },
              }),
              _c("vxe-table-column", {
                attrs: {
                  title: "上账科目编号",
                  field: "accountBudgetSubjectsCode",
                  "min-width": "100",
                },
              }),
              _c("vxe-table-column", {
                attrs: {
                  title: "上账科目",
                  field: "accountBudgetSubjectsName",
                  "min-width": "100",
                },
              }),
              _c("vxe-table-column", {
                attrs: {
                  title: "税金",
                  field: "taxAmount",
                  "min-width": "100",
                },
              }),
              _c("vxe-table-column", {
                attrs: {
                  title: "发票金额（不含税）",
                  field: "invoiceAmount",
                  "min-width": "130",
                },
              }),
              _c("vxe-table-column", {
                attrs: {
                  title: "发票总金额（含税）",
                  field: "countAmount",
                  "min-width": "130",
                },
              }),
              _c("vxe-table-column", {
                attrs: {
                  title: "ERP成本中心",
                  field: "costCenter",
                  "min-width": "100",
                },
              }),
              _c("vxe-table-column", {
                attrs: { title: "摘要", field: "remarks", "min-width": "100" },
              }),
              !_vm.disabled
                ? _c("vxe-table-column", {
                    attrs: {
                      title: "操作",
                      width: "100",
                      fixed: "right",
                      align: "center",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (ref) {
                            var row = ref.row
                            return [
                              _c(
                                "div",
                                { staticClass: "setting-btn" },
                                [
                                  _c(
                                    "el-button",
                                    {
                                      staticStyle: { "margin-right": "10px" },
                                      attrs: {
                                        slot: "reference",
                                        type: "text",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.editFn(row)
                                        },
                                      },
                                      slot: "reference",
                                    },
                                    [_vm._v("编辑")]
                                  ),
                                  _c(
                                    "el-popconfirm",
                                    {
                                      attrs: {
                                        title: "此操作将永久删除该数据？",
                                        "confirm-button-type": "text",
                                      },
                                      on: {
                                        confirm: function ($event) {
                                          return _vm.removeRow(row)
                                        },
                                      },
                                    },
                                    [
                                      _c(
                                        "el-button",
                                        {
                                          staticClass: "text-red",
                                          attrs: {
                                            slot: "reference",
                                            type: "text",
                                          },
                                          slot: "reference",
                                        },
                                        [_vm._v("删除")]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      276511739
                    ),
                  })
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _c("SubModal", { ref: "subModal", on: { resetList: _vm.resetList } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }