import Form, { formCreate } from '../../../../../../components/form';
// import request from '../../../../../../utils/request';

export default {
  extends: Form,
  components: {
    formCreate: formCreate.$form(),
  },
  data() {
    return {
      rule: [
        {
          type: 'select',
          field: 'payType',
          title: '选择支付方式',
          restful: '/tpm/tpmAccountDetailController/payTypeByTypeAndFine',
          restfulParams: {
            fineCodes: this.formConfig.row.fineCodes,
            payTypeType: this.formConfig.row.payTypeType,
          },
          optionsKey: { label: 'dictKey', value: 'dictValue' },
          required: true,
          col: {
            md: { span: 18 },
          },
        },
        {
          type: 'hidden',
          field: 'payTypeName',
          title: '选择支付方式名称',
        },
      ],
    };
  },
  mounted() {
    this.$nextTick(() => {
      const payType = this.getRule('payType');
      payType.on.change = (v) => {
        const subItem = payType.options.filter((item) => item.dictValue === v);
        this.setValue({ payTypeName: subItem[0].dictKey });
      };
    });
  },
  methods: {
    // 提交
    async submit(e) {
      const formData = this.getFormData();

      if (formData) {
        // const { code, row, url } = this.formConfig;

        // let res = {};

        // if (code === 'payment') {
        //   const params = {
        //     ...row,
        //     ...formData,
        //   };

        //   res = await request.post(url, params);
        // }

        // if (res.success) {
        this.$emit('onClose');
        this.$emit('onGetList', formData);
        // }
      }
    },
  },
};
